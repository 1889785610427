<template>
  <div>
    <div v-if="fetching || updating || !dataFetched">
      <div class="loader-container">
        <div class="row justify-content-center">
          <div class="col-md-4 d-flex justify-content-center">
            <div class="loader">Loading...</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <AddProductsModal
          v-if="showLegacyAddProductModal"
          v-bind:productIds=productIds
          @closeAddProductsModal="showLegacyAddProductModal = false;"
          @addLegacyProductToCampaign="addLegacyProductToCampaign"
          @removeLegacyProductFromCampaign="removeLegacyProductFromCampaign"
      />
      <div v-if="showAddProductsModal">
        <div class="modal fade in show modal-active modal-open" id="archiveProductModal">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">Add products to campaign</h4>
                <button type="button" class="close" v-on:click="closeAddProductsModal()">&times;</button>
              </div>
              <!-- Modal body -->
              <div class="modal-body mt-4">
                <div v-if="!productsFetched">
                  <div class="loader-container">
                    <div class="row justify-content-center">
                      <div class="col-md-4 d-flex justify-content-center">
                        <div class="loader">Loading...</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="row mb-4" v-for="product in products" :key="product.id">
                    <div class="col-2">
                      <img v-if="product.primary_thumb_big" class="img-fluid" :src="product.primary_thumb_big" />
                      <img v-else src="@/assets/images/product-photo-coming-soon.jpg" class="img-fluid" alt="">
                    </div>
                    <div class="col-5">
                      <div class="font-weight-bold">{{product.name}}</div>
                      <div>{{product.price}}{{$store.getters.currencyStr}} per item</div>
                      <div>Delivered {{product.delivery_display.toLowerCase()}}</div>
                      <div v-if="product.stock_management">
                        <div v-if="!product.variants.find(v => v.stock_quantity > 0)">
                          <span class="tag-red">Out of stock</span>
                        </div>
                        <div v-else-if="product.variants && product.variants.length == 1">
                          <span class="tag-grey">{{ product.variants[0].stock_quantity }} in stock</span>
                        </div>
                        <div v-else-if="product.variants && product.variants.length > 1">
                      <span v-for="(v, index) of product.variants" v-bind:key="index" :class="{'tag-grey': v.stock_quantity, 'tag-red': !v.stock_quantity}">
                        {{ v.name }} {{ v.stock_quantity }}
                      </span>
                        </div>
                      </div>
                      <div v-else><span class="tag-grey">Produced after order</span></div>
                    </div>
                    <div class="col-5 d-flex align-items-center">
                      <button v-if="productSets[modalProductSetId].products.includes(product.id)" type="button" class="btn btn-lg btn-primary btn-block mt-2 w-100" v-on:click="removeProductFromCampaign(product.id)">Remove</button>
                      <button v-else type="button" class="btn btn-lg btn-outline-primary mt-2 w-100" v-on:click="addProductToCampaign(product.id)">Add</button>
                    </div>
                  </div>
                </div>
              </div><!-- Modal body end -->
              <div class="modal-footer mt-2">
                <button type="button" class="btn btn-lg btn-outline-primary mt-2 w-100" v-on:click="closeAddProductsModal()">Close</button>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-backdrop show"></div>
      </div>
      <AddEmailsModal
          v-if="showAddEmailsModal"
          v-bind:emails=emailsToBeAdded
          v-bind:notValid=notValidEmails
          @closeEmailModalAndClear="closeEmailModalAndClear"
          @addEmailsToCampaign="addEmailsToCampaign"
          @removeEmailFromList="removeEmailFromList"
      />
      <EditEmailsModal
          v-if="showEditEmailsModal"
          v-bind:emails=editEmailsArray
          @closeEditEmailModalAndClear="closeEditEmailModalAndClear"
          @emailToBeRemoved="emailToBeRemoved"
          @removeEmailsFromCampaign="removeEmailsFromCampaign"
      />
      <AddMoreLinksModal
          v-if="showAddMoreEmailsModal"
          v-bind:emails=emailsToBeAdded
          v-bind:notValid=notValidEmails
          v-bind:multiInvitationActive=multiInvitationActive
          @closeMoreLinksModalAndClear="closeMoreLinksModalAndClear"
          @validateEmailsToAdd="validateEmailsToAdd"
          @addMoreLinksToCampaign="addMoreLinksToCampaign"
          @removeEmailFromList="removeEmailFromList"
      />
        <DeleteCampaignModal
            v-if="showDeleteCampaignModal"
            @closeDeleteCampaignModal="showDeleteCampaignModal = false"
            @deleteCampaign="deleteCampaign"
        />
        <DeactivateLinkModal
            v-if="showDeactivateLinkModal"
            v-bind:id="linkIdToDeactivate"
            v-bind:index="linkIndexToDeactivate"
            v-bind:token="linkTokenToDeactivate"
            @closeDeactivateLinkModal="showDeactivateLinkModal = false"
            @deactivateLink="deactivateLink"
        />
      <div class="row">
        <div class="col-12 mt-md-3">
          <h1 class="mt-2">{{editCampaign ? campaign.name : "Create new campaign"}}</h1>
        </div>
      </div>
      <div v-if="editCampaign">
        <div class="row mt-3">
          <div class="col-12 col-md-3">
            <div class="font-weight-bold">Status</div>
            <div>{{campaign.expired ? "Expired" : "Active"}}</div>
          </div>
          <div class="col-12 col-md-3">
            <div class="font-weight-bold">Start date</div>
            <div>{{parseDate(campaign.start_date)}}</div>
          </div>
          <div class="col-12 col-md-3">
            <div class="font-weight-bold">Campaign creator</div>
            <div>{{campaign.creator_name}}</div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <hr />
          </div>
        </div>
      </div>
      <div v-else class="row">
        <div class="col-12 col-md-6 mb-md-5">
          Empower your own team members, retailers, customers and/or brand ambassadors to order your branded products with One-off Links.
        </div>
      </div>
      <SamAccordion
          title="Set campaign details"
          accordionId="samAccordianCampaignDeta"
          :defaultOpen="true"
      >
        <div class="row mb-2">
          <div class="col-12 col-md-3">
            <div class="font-weight-bold">Campaign name*</div>
            <input type="text" class="inputfield" v-model="campaign.name">
            <div v-if="$v.campaign.name.$error">
              <div class="form-field-error" v-if="!$v.campaign.name.required">Can't be empty</div>
            </div>
            <div class="font-weight-bold mt-4">Campaign expiry date* <img id="expiry-tooltip" src="@/assets/images/tooltip.svg" title="Tooltip"/>
              <b-tooltip target="expiry-tooltip" triggers="hover">
                A date when the One-off links expire. Campaign maximum length is 365 days.
              </b-tooltip>
            </div>
            <div><input type="date" class="inputfield" v-model="campaign.expiration_date" :min="today" :max="endDateMax" /></div>
            <div v-if="$v.campaign.expiration_date.$error">
              <div class="form-field-error" v-if="!$v.campaign.expiration_date.required">Can't be empty</div>
            </div>
            <div class="font-weight-bold mt-4">Language*</div>
            <v-select
                label="name"
                v-model="campaign.language"
                :options="languagesArray"
                :reduce="s => s.value"
                :clearable="false"
                placeholder="Select method"
            ></v-select>
          </div>
          <div class="col-12 col-md-3">
            <div class="font-weight-bold">Campaign description</div>
            <div><textarea rows=4 v-model="campaign.description" class="description"/></div>
            <div class="font-weight-bold mt-3">Campaign template*</div>
            <v-select
                label="name"
                :options="templates"
                :reduce="t => t.id"
                v-model="campaign.template"
                :clearable="false"
                placeholder="Select template"
            ></v-select>
          </div>
          <div class="col-12 col-md-3">
            <div class="font-weight-bold">Cost center*</div>
            <v-select
                v-model="campaign.costcenter"
                label="name"
                :options="costcenters"
                :reduce="cc => cc.id"
                :searchable="true"
                placeholder="Select cost center"
            ></v-select>
              <div v-if="$v.campaign.costcenter.$error">
                <div class="form-field-error" v-if="!$v.campaign.costcenter.required">Cost center is required</div>
            </div>
            <div class="font-weight-bold mt-4">Order email notifications <img id="sendOrderNotif-tooltip" src="@/assets/images/tooltip.svg" title="Tooltip"/>
                  <b-tooltip target="sendOrderNotif-tooltip" triggers="hover">
                    Get an email notification of all orders and preorders.
                  </b-tooltip>
              </div>
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" v-model="campaign.order_notifications" id="sendOrderNotif">
              <label class="custom-control-label mt-2 mb-2" for="sendOrderNotif">
                <div>Subscribe to all</div>
              </label>
            </div>
            <div class="font-weight-bold mt-4 mb-0">
              Allow deliveries*
<!--              <img id="addr-tooltip" src="@/assets/images/tooltip.svg" title="Tooltip"/>-->
<!--              <b-tooltip target="addr-tooltip" triggers="hover">-->
<!--                You can limit deliveries for example  to your company's offices.-->
<!--              </b-tooltip>-->
            </div>
            <v-select
              label="name"
              v-model="campaign.limit_addresses"
              :options="[{name: 'Only saved addresses', value: true},
                         {name: 'Only new addresses', value: false},
                         {name: 'Saved and new addresses', value: null}]"
              :reduce="s => s.value"
              :clearable="false"
              placeholder="Select"
            ></v-select>
            <div v-if="campaign.limit_addresses === true || campaign.limit_addresses === null">
              <div class="font-weight-bold mt-4">Only to these saved addresses</div>
              <v-select
                  label="name"
                  :options="addresses"
                  :reduce="a => a.id"
                  multiple
                  v-model="campaign.addresses"
                  placeholder="Select addresses"
              ></v-select>
              <div v-if="$v.campaign.addresses.$error">
                <div class="form-field-error" v-if="!$v.campaign.addresses.addrRequired">Please select at least one address</div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-3 mt-md-0 mt-3">
            <div v-if="allowPayments">
              <div class="font-weight-bold">Collect payments <img id="payments-tooltip" src="@/assets/images/tooltip.svg" title="Tooltip"/>
                <b-tooltip target="payments-tooltip" triggers="hover">
                  You can collect payments from Magic Link recipients when they order products. Just make sure that your products are priced higher than zero.
                </b-tooltip>
              </div>
              <v-select
                  label="name"
                  v-model="campaign.require_payment"
                  :options="[{name: 'Yes', value: true}, {name: 'No', value: false}]"
                  :reduce="s => s.value"
                  :clearable="false"
                  placeholder="Select"
              ></v-select>
              <div v-if="$v.campaign.require_payment.$error">
                <div class="form-field-error" v-if="!$v.campaign.max_order_count.invalidReusedLink">Collect payments are required</div>
              </div>
              <div v-if="!campaign.require_payment">
                <div class="font-weight-bold mt-4">Campaign orders* <img id="order-methods-tooltip" src="@/assets/images/tooltip.svg" title="Tooltip"/>
                <b-tooltip target="order-methods-tooltip" triggers="hover">
                  Create orders immediately when they are made OR collect preorders and approve them once collected.
                </b-tooltip>
                </div>
                <v-select
                  label="name"
                  v-model="campaign.order_method"
                  :options="orderTypesArray"
                  :reduce="s => s.value"
                  :clearable="false"
                  placeholder="Select method"
                  @input="orderMethodChange"
                ></v-select>
                <div v-if="campaign.order_method !== 1">
                <div class="font-weight-bold mt-4">Admins who can approve orders*</div>
                <v-select
                  v-model="campaign.order_approval_admins"
                  :options="userNameList"
                  :reduce="s => s.value"
                  :searchable="false"
                  multiple
                  placeholder="Select admins"
                  @input="orderAdminsChange"
                ></v-select>
                 <div v-if="$v.campaign.order_approval_admins.$error">
                    <div class="form-field-error" v-if="!$v.campaign.order_approval_admins.required">Approval admin(s) is required</div>
                 </div>
                </div>
              </div>

              <div v-if="campaign.require_payment">
                <div class="font-weight-bold mt-4">Allow invoice payments*</div>
                <v-select
                    label="name"
                    v-model="campaign.allow_invoice"
                    :options="[{name: 'Yes', value: true}, {name: 'No', value: false}]"
                    :reduce="s => s.value"
                    :clearable="false"
                    placeholder="Select"
                ></v-select>
              </div>
              <div v-if="showDeliveryFee">
              <div class="font-weight-bold mt-4">Set fixed delivery fee (EUR)*</div>
              <input class="inputfield" v-model.number="campaign.delivery_fee" type="number" step="0.5">
              <div v-if="$v.campaign.delivery_fee.$error">
                <div class="form-field-error" v-if="!$v.campaign.delivery_fee.required">Delivery fee is required</div>
                <div class="form-field-error" v-if="!$v.campaign.delivery_fee.minValue">Delivery fee cannot be negative</div>
              </div>
              </div>
              </div>
            <div v-else>
              <div class="font-weight-bold">Campaign orders* <img id="order-methods-tooltip" src="@/assets/images/tooltip.svg" title="Tooltip"/>
                <b-tooltip target="order-methods-tooltip" triggers="hover">
                  Create orders immediately when they are made OR collect preorders and approve them once collected.
                </b-tooltip>
                </div>
                <v-select
                  label="name"
                  v-model="campaign.order_method"
                  :options="orderTypesArray"
                  :reduce="s => s.value"
                  :clearable="false"
                  placeholder="Select method"
                  @input="orderMethodChange"
                ></v-select>
                <div v-if="campaign.order_method !== 1">
                <div class="font-weight-bold mt-4">Admins who can approve orders*</div>
                <v-select
                  v-model="campaign.order_approval_admins"
                  :options="userNameList"
                  :reduce="s => s.value"
                  :searchable="false"
                  multiple
                  placeholder="Select admins"
                  @input="orderAdminsChange"
                ></v-select>
                <div v-if="$v.campaign.order_approval_admins.$error">
                  <div class="form-field-error" v-if="!$v.campaign.order_approval_admins.required">Approval admin(s) is required</div>
                </div>
                </div>
            </div>
            </div>
          </div>
      </SamAccordion>
      <SamAccordion
          title="Personalise campaign"
          accordionId="samAccordionPersonalise"
          :defaultOpen="true"
          :tooltip="true"
          tooltipText="You can personalise the content of intro emails (if using assigned links) and the intro page before the recipient chooses products as well as the thank you pages after the order."
      >
        <div class="row">
          <div class="col-12 col-md-3">
            <div class="font-weight-bold">Intro email subject* <img id="e-subject-tooltip" src="@/assets/images/tooltip.svg" title="Tooltip"/>
              <b-tooltip target="e-subject-tooltip" triggers="hover">
                Email subject field recipients will see if you send Magic Links to assigned recipients.
              </b-tooltip>
            </div>
            <input type="text" class="inputfield" v-model="campaign.email_subject">
            <div v-if="$v.campaign.email_subject.$error">
              <div class="form-field-error" v-if="!$v.campaign.email_subject.required">This field is required.</div>
            </div>
            <div class="font-weight-bold mt-3">Intro email from name* <img id="e-from-tooltip" src="@/assets/images/tooltip.svg" title="Tooltip"/>
              <b-tooltip target="e-from-tooltip" triggers="hover">
                Emails will be sent from Framme email address, but you can change the name they'll see when they get the email.
              </b-tooltip>
            </div>
            <input type="text" class="inputfield" v-model="campaign.email_from_name">
            <div v-if="$v.campaign.email_from_name.$error">
              <div class="form-field-error" v-if="!$v.campaign.email_from_name.required">This field is required.</div>
              <div class="form-field-error" v-else-if="!$v.campaign.email_from_name.invalidChars">Please do not use comma (,)</div>
            </div>
            <div class="font-weight-bold mt-3">Intro email & intro page image* <img id="e-hero-tooltip" src="@/assets/images/tooltip.svg" title="Tooltip"/>
              <b-tooltip target="e-hero-tooltip" triggers="hover">
                By default, the first image of the first product is shown in the intro email and page. However, you can change this by uploading a new photo from your computer.
              </b-tooltip>
            </div>
            <div v-if="heroImage">
              <img class="img-width" :src="heroImage" />
            </div>
            <div v-else>
              <img v-if="legacyProductObjects.length > 0" class="w-100" :src="legacyProductObjects[0].primary_thumb_big" />
              <img v-else-if="productObjects.length > 0" class="w-100" :src="productObjects[0].primary_thumb_big" />
            </div>
            <div>
              <label for="hero_image" class="custom-file-upload mt-3">
                <i class="fa fa-cloud-upload"></i> Choose file
              </label>
              <input class="inputfile" type="file" id="hero_image" ref="hero_image" v-on:change="handleFileUpload()"/>
            </div>
            <div v-if="heroImage">
              <button class="btn btn-lg btn-outline-primary btn-block mt-2" v-on:click="resetHeroImage()">Reset to default</button>
            </div>
          </div>
          <div class="col-12 col-md-3">
            <div class="font-weight-bold">Intro email title*</div>
            <input type="text" class="inputfield" v-model="campaign.email_header">
            <div v-if="$v.campaign.email_header.$error">
              <div class="form-field-error" v-if="!$v.campaign.email_header.required">This field is required.</div>
            </div>
            <div class="font-weight-bold mt-3">Intro email text*</div>
            <div><textarea rows=6 v-model="campaign.email_content" /></div>
            <div v-if="$v.campaign.email_content.$error">
              <div class="form-field-error" v-if="!$v.campaign.email_content.required">This field is required.</div>
            </div>
            <div class="font-weight-bold mt-3">Intro email signature</div>
            <input type="text" class="inputfield" v-model="campaign.email_signature">
          </div>
          <div class="col-12 col-md-3">
            <div class="font-weight-bold">Intro page header*</div>
            <input type="text" class="inputfield" v-model="campaign.intro_header">
            <div v-if="$v.campaign.intro_header.$error">
              <div class="form-field-error" v-if="!$v.campaign.intro_header.required">This field is required.</div>
            </div>
            <div class="font-weight-bold mt-3">Intro page text*</div>
            <div><textarea rows=6 v-model="campaign.intro_text" /></div>
            <div v-if="$v.campaign.intro_text.$error">
              <div class="form-field-error" v-if="!$v.campaign.intro_text.required">This field is required.</div>
            </div>
            <div class="font-weight-bold mt-3">Intro page signature</div>
            <input type="text" class="inputfield" v-model="campaign.intro_signature">
          </div>
          <div class="col-12 col-md-3">
            <div class="font-weight-bold">Thank you page header*</div>
            <input type="text" class="inputfield" v-model="campaign.thanks_header">
            <div v-if="$v.campaign.thanks_header.$error">
              <div class="form-field-error" v-if="!$v.campaign.thanks_header.required">This field is required.</div>
            </div>
            <div class="font-weight-bold mt-3">Thank you page text*</div>
            <div><textarea rows=6 v-model="campaign.thanks_text" /></div>
            <div v-if="$v.campaign.thanks_text.$error">
              <div class="form-field-error" v-if="!$v.campaign.thanks_text.required">This field is required.</div>
            </div>
            <div class="font-weight-bold mt-3">Thank you page signature</div>
            <input type="text" class="inputfield" v-model="campaign.thanks_signature">
            <div class="font-weight-bold mt-3">Order conf. email support text* <img id="e-support-tooltip" src="@/assets/images/tooltip.svg" title="Tooltip"/>
              <b-tooltip target="e-support-tooltip" triggers="hover">
                This support section will appear in the recipient's order confirmation email. Please provide them with contact details and a channel for support.
              </b-tooltip>
            </div>
            <div><textarea rows=6 v-model="campaign.email_support_text" /></div>
            <div v-if="$v.campaign.email_support_text.$error">
              <div class="form-field-error" v-if="!$v.campaign.email_support_text.required">This field is required.</div>
            </div>
          </div>
        </div>
      </SamAccordion>
      <SamAccordion
          title="Select products"
          accordionId="samAccordionSelectProduct"
          :defaultOpen="true"
      >
        <div v-if="productIds.length > 0">
          <h3 style="font-weight: 600; font-size: 16px; line-height: 24px">
            Products set 1
            <span class="badge badge-product-set">?</span>
          </h3>
          <div class="row">
            <div class="col-md-3" v-for="p in legacyProductObjects" v-bind:key="p.id">
              <div class="row">
                <div class="col-5">
                  <img class="img-fluid" v-if="p.primary_thumb_big" :src="p.primary_thumb_big" />
                  <img v-else src="@/assets/images/product-photo-coming-soon.jpg" class="img-fluid" alt="">
                </div>
                <div class="col-7 pl-0">
                  <div class="font-weight-bold">{{p.name}}</div>
                  <div class="price">{{p.price}}{{$store.getters.currencyStr}} per item</div>
                  <div>Delivered {{p.delivery_display.toLowerCase()}}</div>
                  <div v-if="p.stock_management">
                    <div v-if="!p.variants.find(v => v.stock_quantity > 0)">
                      <span class="tag-red">Out of stock</span>
                    </div>
                    <div v-else-if="p.variants && p.variants.length == 1">
                      <span class="tag-grey">{{ p.variants[0].stock_quantity }} in stock</span>
                    </div>
                    <div v-else-if="p.variants && p.variants.length > 1">
                    <span v-for="(v, index) of p.variants" v-bind:key="index" :class="{'tag-grey': v.stock_quantity, 'tag-red': !v.stock_quantity}">
                      {{ v.name }} {{ v.stock_quantity }}
                    </span>
                    </div>
                  </div>
                  <div v-else><span class="tag-grey">Produced after order</span></div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-3 d-flex align-items-end">
              <button
                  class="btn btn-lg btn-dark btn-add-product btn-block mt-2"
                  v-on:click="showLegacyAddProductModal = true"
              >Add or remove products</button>
            </div>
            <div class="col-md-3">
              <div class="font-weight-bold">Limit orders*</div>
              <v-select
                  label="name"
                  v-model="campaign.order_limit_type"
                  :options="orderLimitsArray"
                  :reduce="s => s.value"
                  :clearable="false"
                  v-on:input="limitProductSets"
                  placeholder="Select quantity"
              ></v-select>
            </div>
            <div class="col-md-3">
              <div v-if="productIds.length > 0 && campaign.order_limit_type === 1">
                <div class="font-weight-bold">Max. order quantity per order*</div>
                <v-select
                    label="name"
                    v-model="campaign.max_order_count"
                    :options="quantityArray"
                    :reduce="s => s.value"
                    :clearable="false"
                    placeholder="Select quantity"
                ></v-select>
                <div v-if="$v.campaign.max_order_count.$error">
                  <div class="form-field-error" v-if="!$v.campaign.max_order_count.required">Choose quantity</div>
                  <div class="form-field-error" v-else-if="!$v.campaign.max_order_count.minValue">Must be at least 1</div>
                </div>
              </div>
              <div v-else-if="campaign.order_limit_type === 2">
                <div class="font-weight-bold">Max budget {{ storeCurrency }} per order* <img id="order-budget-tooltip" src="@/assets/images/tooltip.svg" title="Tooltip"/>
                  <b-tooltip target="order-budget-tooltip" triggers="hover">
                    Must be a positive value.
                  </b-tooltip>
                </div>
                <input type="text" class="inputfield" v-model="campaign.order_budget">
                <div v-if="$v.campaign.order_budget.$error">
                  <div class="form-field-error" v-if="!$v.campaign.order_budget.required">This field is required.</div>
                  <div class="form-field-error" v-if="!$v.campaign.order_budget.integer || !$v.campaign.order_budget.minValue">This field must be a positive number.</div>
                </div>
              </div>
              <div v-else-if="campaign.order_limit_type === 3">
                <div class="font-weight-bold">Max. order quantity per order*</div>
                <input type="text" class="inputfield" disabled placeholder="Unlimited">
              </div>
            </div>
            <div class="col-md-3 d-flex align-items-end">
              <button
                  v-if="showFirstAddProductSetButton"
                  class="btn btn-lg btn-outline-primary btn-block mt-2"
                  v-on:click="addProductSet"
              >+ Add product set</button>
            </div>
          </div>
        </div>
        <div class="mb-5" v-for="(ps, idx) in productSets" :key="idx">
          <h3 style="font-weight: 600; font-size: 16px; line-height: 24px">
            Product set {{ idx + (isLegacyCampaign ? 2 : 1) }}
            <span v-if="idx === 0 && productIds.length === 0" class="badge badge-product-set">?</span>
            <span v-else class="badge badge-product-set" style="cursor: pointer" v-on:click="removeProductSet(idx)">-</span>
          </h3>
          <product-set :productObjects="getProductSetProductObjects(ps)" />
          <div class="row mt-3">
            <div class="col-md-3 d-flex align-items-end">
              <button class="btn btn-lg btn-dark btn-add-product btn-block mt-2" v-on:click="openAddProductsModal(idx)">Add or remove products</button>
            </div>
            <div class="col-md-3">
              <div v-if="idx === 0 && !isLegacyCampaign">
                <div class="font-weight-bold">Limit orders*</div>
                <v-select
                    label="name"
                    v-model="campaign.order_limit_type"
                    :options="orderLimitsArray"
                    :reduce="s => s.value"
                    :clearable="false"
                    v-on:input="limitProductSets"
                    placeholder="Select quantity"
                ></v-select>
              </div>
              <div v-else>
                <div class="font-weight-bold">Limit orders*</div>
                <input v-if="campaign.order_limit_type === 1" type="text" class="inputfield" disabled placeholder="Limit to one per product">
                <input v-else-if="campaign.order_limit_type === 3" type="text" class="inputfield" disabled placeholder="Unlimited">
              </div>
            </div>
            <div class="col-md-3">
              <div v-if="productSets[idx].products.length > 0 && campaign.order_limit_type === 1">
                <div class="font-weight-bold">Max. order quantity per order*</div>
                <v-select
                    label="name"
                    v-model="productSets[idx].max_order_count"
                    :options="getProductSetQuantity(ps)"
                    :reduce="s => s.value"
                    :clearable="false"
                    placeholder="Select quantity"
                ></v-select>
                <div v-if="$v.productSets.$each[idx].max_order_count.$error">
                  <div class="form-field-error" v-if="!$v.productSets.$each[idx].max_order_count.required">Choose quantity</div>
                  <div class="form-field-error" v-else-if="!$v.productSets.$each[idx].max_order_count.minValue">Must be at least 1</div>
                </div>
              </div>
              <div v-else-if="campaign.order_limit_type === 2">
                <div class="font-weight-bold">Max budget {{ storeCurrency }} per order* <img id="order-budget-tooltip" src="@/assets/images/tooltip.svg" title="Tooltip"/>
                  <b-tooltip target="order-budget-tooltip" triggers="hover">
                    Must be a positive value.
                  </b-tooltip>
                </div>
                <input type="text" class="inputfield" v-model="campaign.order_budget">
                <div v-if="$v.campaign.order_budget.$error">
                  <div class="form-field-error" v-if="!$v.campaign.order_budget.required">This field is required.</div>
                  <div class="form-field-error" v-if="!$v.campaign.order_budget.integer || !$v.campaign.order_budget.minValue">This field must be a positive number.</div>
                </div>
              </div>
              <div v-else-if="campaign.order_limit_type === 3">
                <div class="font-weight-bold">Max. order quantity per order*</div>
                <input type="text" class="inputfield" disabled placeholder="Unlimited">
              </div>
            </div>
            <div class="col-md-3 d-flex align-items-end">
              <button
                  v-if="productSets.length === idx + 1 && getProductSetProductsNumber(productSets[idx]) > 0 && campaign.order_limit_type === 1"
                  class="btn btn-lg btn-outline-primary btn-block mt-2"
                  v-on:click="addProductSet"
              >+ Add product set</button>
            </div>
          </div>
        </div>
      </SamAccordion>
      <SamAccordion
          title="Add extra fields"
          accordionId="samAccordianExtraField"
          :defaultOpen="true"
      >
        <div class="row">
          <div class="col-md-3">
            <div class="font-weight-bold">Ask recipients <img id="ask-tooltip" src="@/assets/images/tooltip.svg" title="Tooltip"/>
              <b-tooltip target="ask-tooltip" triggers="hover">
                A question you can ask from your recipients.
              </b-tooltip>
            </div>
            <v-select
                label="name"
                v-model="campaign.question_included"
                :options="[{name: 'Yes', value: true}, {name: 'No', value: false}]"
                :reduce="s => s.value"
                :clearable="false"
                placeholder="Select"
            ></v-select>
          </div>
          <div class="col-md-9" v-if="campaign.question_included">
            <div class="row">
              <div class="col-md-4">
                <div class="font-weight-bold">Your question</div>
                <div><textarea rows=4 v-model="campaign.question_text" /></div>
              </div>
              <div class="col-md-4">
                <div class="font-weight-bold">Is your question mandatory? <img id="mandatory-q-tooltip" src="@/assets/images/tooltip.svg" title="Tooltip"/>
                  <b-tooltip target="mandatory-q-tooltip" triggers="hover">
                    Mandatory for Recipient to Answer
                  </b-tooltip>
                </div>
                <v-select
                    label="name"
                    v-model="campaign.question_mandatory"
                    :options="[{name: 'Yes', value: true}, {name: 'No', value: false}]"
                    :reduce="s => s.value"
                    :clearable="false"
                    placeholder="Select"
                ></v-select>
              </div>
              <div class="col-md-4">
                <div class="font-weight-bold">Allow attachment upload <img id="attach-tooltip" src="@/assets/images/tooltip.svg" title="Tooltip"/>
                  <b-tooltip target="attach-tooltip" triggers="hover">
                    You can allow recipients to upload a file, like their company logo, as part of the Magic Link order.
                  </b-tooltip>
                </div>
                <v-select
                    label="name"
                    v-model="campaign.order_attachment"
                    :options="[{name: 'Yes', value: true}, {name: 'No', value: false}]"
                    :reduce="s => s.value"
                    :clearable="false"
                    placeholder="Select"
                ></v-select>
              </div>
            </div>
          </div>
        </div>
      </SamAccordion>
<!--      <div v-if="editCampaign">-->
      <SamAccordion
          title="Add call-to-action buttons"
          accordionId="samAccordianActionButtons"
          :defaultOpen="true"
      >
        <div class="row">
          <div class="col-12 col-md-3">
            <div class="font-weight-bold">Primary button text</div>
            <input type="text" class="inputfield" v-model="campaign.primary_action_text">
            <div v-if="$v.campaign.primary_action_text.$error">
              <div class="form-field-error" v-if="!$v.campaign.primary_action_text.required">This field is required.</div>
            </div>
          </div>
          <div class="col-12 col-md-3">
            <div class="font-weight-bold">Primary button URL</div>
            <input type="text" class="inputfield" v-model="campaign.primary_action_url">
            <div v-if="$v.campaign.primary_action_url.$error">
              <div class="form-field-error" v-if="!$v.campaign.primary_action_url.required">URL is required.</div>
              <div class="form-field-error" v-if="!$v.campaign.primary_action_url.url">URL must be valid.</div>
            </div>
          </div>
          <div class="col-12 col-md-3">
            <div class="font-weight-bold">Secondary button text</div>
            <input type="text" class="inputfield" v-model="campaign.secondary_action_text">
            <div v-if="$v.campaign.secondary_action_text.$error">
              <div class="form-field-error" v-if="!$v.campaign.secondary_action_text.required">This field is required.</div>
            </div>
          </div>
          <div class="col-12 col-md-3">
            <div class="font-weight-bold">Secondary button URL</div>
            <input type="text" class="inputfield" v-model="campaign.secondary_action_url">
            <div v-if="$v.campaign.secondary_action_url.$error">
              <div class="form-field-error" v-if="!$v.campaign.secondary_action_url.required">URL is required.</div>
              <div class="form-field-error" v-if="!$v.campaign.secondary_action_url.url">URL must be valid.</div>
            </div>
          </div>
        </div>
      </SamAccordion>
      <SamAccordion
          title="Add shipping details"
          accordionId="samAccordianShippingdetails"
          :defaultOpen="true"
      >
        <div v-if="!activeCarriers" class="row">
          <div class="col-md-10">
            Request your admin(s) to activate carriers and/or add product dimensions to see shipping info
            and to receive tracking codes.
          </div>
        </div>
        <div v-else class="row">
          <div class="col-md-3">
            <div class="font-weight-bold">Select carrier <img id="carrier-tooltip" src="@/assets/images/tooltip.svg" title="Tooltip"/>
              <b-tooltip target="carrier-tooltip" triggers="hover">
                Select a carrier to be used in this campaign. If you select a carrier then selecting a shipping option is mandatory.
              </b-tooltip>
            </div>
            <v-select
                label="name"
                v-model="campaign.store_campaign_carrier[0].carrier"
                :options=storeCarriers
                :reduce="s => s.value"
                placeholder="Select"
                :searchable="true"
            ></v-select>
          </div>
          <div class="col-md-3">
            <div class="font-weight-bold">Select shipping option <img id="carrier-shipping-tooltip" src="@/assets/images/tooltip.svg" title="Tooltip"/>
              <b-tooltip target="carrier-shipping-tooltip" triggers="hover">
                Select delivery option to be used in this campaign.
              </b-tooltip>
            </div>
            <v-select
                label="name"
                v-model="campaign.store_campaign_carrier[0].shipping_option"
                :options=carrierShippingOptions
                :reduce="s => s.value"
                :searchable="true"
                placeholder="Select"
            ></v-select>
            <div v-if="$v.campaign.store_campaign_carrier.$each[0].shipping_option.$error">
                <div class="form-field-error" v-if="!$v.campaign.store_campaign_carrier.$each[0].shipping_option.required">Select shipping option</div>
              </div>
          </div>
        </div>
      </SamAccordion>
        <SamAccordion
            v-if="editCampaign"
            title="Magic Links:"
            :title-payload="`${filteredOrdersCount}/${filteredLinksCount} ${totalOrdersCount === 1 ? 'order' : 'orders'} (${ordersUsedPercentage}% redeem rate)`"
            accordionId="samAccordionMagicLinks"
            :defaultOpen="true"
        >
          <div class="row mb-2">
            <div class="col-12 col-md-3 mb-3">
              <v-select
                  v-model="linkFilters.tokenFilter"
                  :options="linkTokenOptions"
                  :searchable="true"
                  :disabled="false"
                  placeholder="All Magic Link IDs"
              ></v-select>
            </div>
            <div class="col-12 col-md-3 mb-3">
              <v-select
                  v-model="linkFilters.typeFilter"
                  :options="linkTypeOptions"
                  :searchable="true"
                  :disabled="false"
                  placeholder="All link types"
              ></v-select>
            </div>
            <div class="col-12 col-md-3 mb-3">
              <v-select
                  v-model="linkFilters.statusFilter"
                  :options="linkStatusOptions"
                  :searchable="true"
                  :disabled="false"
                  placeholder="All statuses"
              ></v-select>
            </div>
            <div class="col-12 col-md-3 mb-3">
              <v-select
                  v-model="linkFilters.recipientFilter"
                  :options="linkRecipientOptions"
                  :searchable="true"
                  :disabled="false"
                  placeholder="All recipients"
              ></v-select>
            </div>
          </div>
          <div v-if="!filteredLinks">
            <div class="loader-container">
              <div class="row justify-content-center">
                <div class="col-md-4 d-flex justify-content-center">
                  <div class="loader">Loading...</div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div v-for="(i, idx) in filteredLinks" :key="idx">
              <!--    Desktop    -->
              <div class="row d-none d-md-flex py-2">
                <div class="col-md-3 px-md-4 d-flex justify-content-between">
                  <div>{{ i.token !== null && i.token !== undefined ? i.token : 'N/A' }}</div>
                  <div>
                    <span v-if="i.isApiOrder" class="tag-green"><router-link style="color: #fff;" :to="{ name: 'orders', params: {tab: 'view', itemId: i.id}}">View order</router-link></span>
                    <span v-else-if="i.isMultiInvitation" class="tag-green"><router-link style="color: #fff;" :to="{ name: 'orders', params: {tab: 'view', itemId: i.id}}">View order</router-link></span>
                    <span v-else-if="i.send_email && i.order" class="tag-green"><router-link style="color: #fff;" :to="{ name: 'orders', params: {tab: 'view', itemId: i.order}}">View order</router-link></span>
                    <span v-else-if="i.send_email && !i.order && i.active === true" class="grey-pointer tag-grey" @click="resendLink(idx, i.id)">Resend link</span>
                    <span v-else-if="!i.used && !i.copied && i.active === true" class="ml-2 grey-pointer tag-grey" @click="copyLink(idx, i.id)">Copy URL</span>
                    <span v-else-if="!i.used && i.copied" @mouseleave="hoverId = null" @mouseover="hoverId = i.id">
                      <span v-if="hoverId === i.id && i.active === true" class="ml-2 grey-pointer tag-grey" @click="copyLink(idx, i.id)">Copy URL</span>
                      <span v-else-if="!i.used && i.copied && i.active === true" class="ml-2 grey-pointer tag-black" @click="copyLink(idx, i.id)">URL copied</span>
                    </span>
                    <span v-else-if="i.used && i.copied && i.order" class="tag-green"><router-link style="color: #fff;" :to="{ name: 'orders', params: {tab: 'view', itemId: i.order}}">View order</router-link></span>
                    <div style="position: absolute; left: -9999px"> <!-- hidden element for link copy -->
                      <input :id="'link_' + idx" type="text" :value="getLink(i.token)"/>
                    </div>
                  </div>
                </div>
                <div class="col-md-3 px-md-4">{{ getLinkType(i) }}</div>
                <div class="col-md-3 px-md-4 d-flex justify-content-between">
                  <div>{{ getLinkStatus(i) }}</div>
                  <div>
                    <span v-if="i.active === true && !i.order" class="ml-2 grey-pointer tag-grey" v-on:click="openDeactivateLink(idx, i.id, i.token)">Deactivate</span>
                    <span v-else-if="i.active === false" class="ml-2 tag-red">Deactivated</span>
                  </div>
                </div>
                <div class="col-md-3 px-md-4">{{ getLinkRecipient(i) }}</div>
              </div>
              <!--    Mobile    -->
              <div class="row d-md-none py-2">
                <div class="col-6">
                  <div class="font-weight-bold">Link ID</div>
                  <div class="d-flex justify-content-between">
                    <div>{{ i.token !== null && i.token !== undefined ? i.token : 'N/A' }}</div>
                    <div>
                      <span v-if="i.isApiOrder" class="tag-green"><router-link style="color: #fff;" :to="{ name: 'orders', params: {tab: 'view', itemId: i.id}}">View order</router-link></span>
                      <span v-else-if="i.isMultiInvitation" class="tag-green"><router-link style="color: #fff;" :to="{ name: 'orders', params: {tab: 'view', itemId: i.id}}">View order</router-link></span>
                      <span v-else-if="i.send_email && i.order" class="tag-green"><router-link style="color: #fff;" :to="{ name: 'orders', params: {tab: 'view', itemId: i.order}}">View order</router-link></span>
                      <span v-else-if="i.send_email && !i.order && i.active === true" class="grey-pointer tag-grey" @click="resendLink(idx, i.id)">Resend link</span>
                      <span v-else-if="!i.used && !i.copied && i.active === true" class="ml-2 grey-pointer tag-grey" @click="copyLink(idx, i.id)">aCopy URL</span>
                      <span v-else-if="!i.used && i.copied" @mouseleave="hoverId = null" @mouseover="hoverId = i.id">
                        <span v-if="hoverId === i.id && i.active === true" class="ml-2 grey-pointer tag-grey" @click="copyLink(idx, i.id)">bCopy URL</span>
                        <span v-else-if="!i.used && i.copied && i.active === true" class="ml-2 grey-pointer tag-black" @click="copyLink(idx, i.id)">URL copied</span>
                      </span>
                      <span v-else-if="i.used && i.copied" class="tag-green"><router-link style="color: #fff;" :to="{ name: 'orders', params: {tab: 'view', itemId: i.order}}">View order</router-link></span>
                      <div style="position: absolute; left: -9999px"> <!-- hidden element for link copy -->
                        <input :id="'link_' + idx" type="text" :value="getLink(i.token)"/>
                      </div>
                    </div>
                  </div>
                  <div class="font-weight-bold mt-3">Link type</div>
                  <div>{{ getLinkType(i) }}</div>
                </div>
                <div class="col-6">
                  <div class="font-weight-bold">Status</div>
                  <div class="d-flex justify-content-between">
                    <div>{{ getLinkStatus(i) }}</div>
                    <div>
                      <span v-if="i.active === true && !i.order" class="ml-2 grey-pointer tag-grey" v-on:click="openDeactivateLink(idx, i.id, i.token)">Deactivate</span>
                      <span v-else-if="i.active === false" class="ml-2 tag-red">Deactivated</span>
                    </div>
                  </div>
                  <div class="font-weight-bold mt-3">Recipient</div>
                  <div>{{ getLinkRecipient(i) }}</div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <hr class="my-2" />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-3">
              <button class="btn btn-lg btn-outline-primary btn-block mt-3" v-on:click="copyAllLinks()">Copy All One-Off Links</button>
            </div>
            <div style="position: absolute; left: -9999px"> <!-- hidden element for link copy -->
              <textarea id="all_links" :value="getAllLinks"></textarea>
            </div>
            <div v-if="multiInvitationActive && multiInvitationLinkExists" class="col-12 col-md-3">
              <button class="btn btn-lg btn-outline-primary btn-block mt-3" @click="copyReusableLinkToClipboard">Copy reusable link</button>
            </div>
            <div class="col-12 col-md-3">
              <button class="btn btn-lg btn-outline-primary btn-block mt-3" v-on:click="openAddLinksModal()">+ Add or edit links</button>
            </div>
          </div>
        </SamAccordion>
        <SamAccordion
            v-else
            title="Create Magic Links"
            accordionId="samAccordionMagicLinks"
            :defaultOpen="true"
        >
          <div class="row">
            <div class="col-12 col-md-3">
              <p>You can send Magic Links to recipients in 3 different ways:</p>
              <p>A) Add recipients' emails and we'll send links to them automatically when you Start this campaign.</p>
              <!--              <p>And/or</p>-->
              <p>B) Generate unassigned Magic Links and send them manually from your own email or other messaging tools.</p>
              <p>C) Generate one reusable link and send it manually from your own email or other messaging tools.</p>
            </div>
            <div class="col-12 col-md-3">
              <div>A) Recipient email addresses <img id="emails-tooltip" src="@/assets/images/tooltip.svg" title="Tooltip"/>
                <b-tooltip target="emails-tooltip" triggers="hover">
                  We send recipients an email with the introduction header, text and signature you've defined earlier, and with a button that opens up the Magic Link.
                </b-tooltip>
              </div>
              <div><textarea rows=6 v-model="rawRecipientList" /></div>
              <button class="btn btn-lg btn-outline-primary btn-block mt-2" v-on:click="validateAndAddEmails(true)">Add recipients to campaign</button>
              <div class="font-weight-bold font-green mt-2 pointer" @click="showEditEmailsModal = true">{{validatedEmails.length}} recipients added. <span v-if="validatedEmails.length > 0"><u>Edit.</u></span></div>
              <div v-if="$v.validatedEmails.$error">
                <div class="form-field-error" v-if="!$v.validatedEmails.required">A) B) or c) is required.</div>
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div>B) Number of unassigned links <img id="links-tooltip" src="@/assets/images/tooltip.svg" title="Tooltip"/>
                <b-tooltip target="links-tooltip" triggers="hover">
                  These unassigned Magic Links won't be sent automatically to anyone.
                </b-tooltip>
              </div>
              <div><input type="number" class="inputfield" min="0" v-model="linkCount"></div>
              <div v-if="$v.linkCount.$error">
                <div class="form-field-error" v-if="!$v.linkCount.required">A) B) or c) is required.</div>
                <div class="form-field-error" v-if="!$v.linkCount.minValue">Must be 0 or bigger</div>
                <div class="form-field-error" v-if="!$v.linkCount.maxValue">Must be less than 1000</div>
              </div>
            </div>
            <div class="col-12 col-md-3">
              <div>C) Create one reusable link <img id="reusable_link-tooltip" src="@/assets/images/tooltip.svg" title="Tooltip"/>
                <b-tooltip target="reusable_link-tooltip" triggers="hover">
                  This alternative is not recommended unless you collect payments from recipients.
                </b-tooltip>
              </div>
              <v-select
                  label="name"
                  v-model="campaign.reusable_link"
                  :options="[{name: 'Yes', value: true}, {name: 'No', value: false}]"
                  :reduce="s => s.value"
                  :clearable="false"
                  placeholder="Select"
              ></v-select>
              <div v-if="$v.campaign.reusable_link.$error">
                <div class="form-field-error" v-if="!$v.campaign.reusable_link.required">A) B) or c) is required.</div>
              </div>
            </div>
          </div>
        </SamAccordion>
<!--      </div>-->
        <div class="row">
            <div class="col-12 col-md-3">
                <button class="btn btn-lg btn-primary btn-block mt-0" v-on:click="saveCampaign()">{{ editCampaign ? "Save changes" : "Start campaign"}}</button>
            </div>
            <div class="col-12 col-md-3">
                <router-link :to="{ name: 'campaigns'}">
                    <button class="btn btn-lg btn-outline-primary btn-block mt-3 mt-md-0">Back to all campaigns</button>
                </router-link>
            </div>
            <div v-if="this.editCampaign" class="col-12 col-md-3">
                    <button class="btn btn-lg btn-outline-primary btn-block mt-3 mt-md-0" v-on:click="duplicateCampaign()">Duplicate campaign</button>
            </div>
            <div v-if="this.editCampaign" class="col-12 col-md-3">
                <button class="btn btn-lg btn-danger btn-block mt-3 mt-md-0" v-on:click="showDeleteCampaignModal = true">Delete campaign</button>
            </div>
        </div>
    </div>
  </div>
</template>

<script>

import AddProductsModal from './AddProductsModal.vue'
import AddEmailsModal from './AddEmailsModal.vue'
import EditEmailsModal from './EditEmailsModal.vue'
import AddMoreLinksModal from './AddMoreLinksModal.vue'
import DeleteCampaignModal from './DeleteCampaignModal.vue'
import DeactivateLinkModal from './DeactivateLinkModal.vue'
import ProductSet from "./ProductSet.vue";
import SamAccordion from '../../../components/SamAccordion.vue'
import {required, minValue, maxValue, email, requiredIf, integer, url} from 'vuelidate/lib/validators'

export default {
  name: 'CreateEditCampaign',
  components: {
    AddProductsModal,
    AddEmailsModal,
    EditEmailsModal,
    AddMoreLinksModal,
    DeleteCampaignModal,
    DeactivateLinkModal,
    ProductSet,
    SamAccordion
  },
  data() {
    return {
      updating: false,
      activeCarriers: false,
      campaign: {
        question_included: false,
        question_mandatory: false,
        order_attachment: false,
        language: "en",
        order_method: 1,
        order_approval_admins: [],
        order_notifications: true,
        products: [],
        product_sets: [],
        intro_header: "",
        intro_text: "",
        intro_signature: "",
        thanks_header: "",
        thanks_text: "",
        thanks_signature: "",
        email_support_text: "",
        require_payment: false,
        allow_invoice: false,
        limit_addresses: false,
        addresses: [],
        hero_image: null,
        email_subject: "",
        email_from_name: "",
        email_header: "Hello there!",
        email_content: "",
        email_signature: "",
        order_limit_type: 1,
        order_budget: null,
        max_order_count: null,
        template: null,
        reusable_link: false,
        delivery_fee: 0,
        store_campaign_carrier: [{"carrier":null}],
        primary_action_text: "",
        primary_action_url: "",
        secondary_action_text: "",
        secondary_action_url: ""
      },
      productSets: [
        {
          id: null,
          campaign: null,
          order_limit_type: 1,
          max_order_count: null,
          products: []
        }
      ],
      productIds: [],
      isLegacyCampaign: false,
      linkCount: 0,
      linkFilters: {
        tokenFilter: null,
        typeFilter: null,
        statusFilter: null,
        recipientFilter: null
      },
      showAddProductsModal: false,
      showLegacyAddProductModal: false,
      modalProductSetId: null,
      rawRecipientList: "",
      validatedEmails: [],
      showAddEmailsModal: false,
      emailsToBeAdded: [],
      notValidEmails: [],
      linkIdToDeactivate: null,
      linkIndexToDeactivate: null,
      linkTokenToDeactivate: null,
      showEditEmailsModal: false,
      emailsToBeRemoved: [],
      hoverId: null,
      showAddMoreEmailsModal: false,
      showDeleteCampaignModal: false,
      showDeactivateLinkModal: false,
      hero_image: null,
      showHeroPreview: false,
      heroPreview: null,
      campaign_admin: null,
      acceptedMimeTypes: [
        'image/gif',
        'image/jpeg',
        'image/png',
        'image/webp'
      ],
    }
  },
  validations: {
    campaign: {
      addresses: {
        addrRequired(addresses){
          if ((this.campaign.limit_addresses === true || this.campaign.limit_addresses === null) && addresses.length === 0) {
            return false
          }
          return true
        }
      },
      costcenter: {
        required
      },
      name: {
        required
      },
      expiration_date: {
        required
      },
      max_order_count: {
        required: requiredIf(function(){
          return this.campaign.order_limit_type === 1 && this.isLegacyCampaign;
        }),
        minValue: minValue(1)
        // TODO also check max value is less than total prod count
      },
      intro_header: {
        required
      },
      intro_text: {
        required
      },
      thanks_header: {
        required
      },
      thanks_text: {
        required
      },
      email_support_text: {
        required
      },
      email_subject: {
        required
      },
      email_from_name: {
        required,
        invalidChars(name){
          if(name == "" || name == null){
            return true;
          }
          // Check email doesn't contain some forbidden characters
          return ![','].some(e => name.includes(e));
        },
      },
      email_header: {
        required
      },
      email_content: {
        required
      },
      require_payment: {
        // invalidReusedLink: function(isActive) {
        //   // console.log('isActive', isActive)
        //   return !(this.campaign.reusable_link && !isActive);
        // }
      },
      order_budget: {
        required: requiredIf(function() {
          return this.campaign.order_limit_type === 2
        }),
        integer,
        minValue: minValue(1)
      },
      reusable_link: {
        requiredIf: function() {
          if (this.editCampaign) {
            return true
          }
          return this.magicLinksSelected
        }
      },
      delivery_fee: {
        required: requiredIf(function(){
          return this.showDeliveryFee;
        }),
        minValue: minValue(0)
      },
      store_campaign_carrier:{
        $each: {
          shipping_option: {
            required: requiredIf(function () {
              return this.carrierSelected
            })
          }
        }
      },
      order_approval_admins: {
        required: requiredIf(function () {
              return this.campaign.order_method === 2
            })
      },
      primary_action_text: {
        required: requiredIf(function() {
          return this.campaign.primary_action_url
        }),
      },
      primary_action_url: {
        required: requiredIf(function() {
          return this.campaign.primary_action_text
        }),
        url,
      },
      secondary_action_text: {
        required: requiredIf(function() {
          return this.campaign.secondary_action_url
        }),
      },
      secondary_action_url: {
        required: requiredIf(function() {
          return this.campaign.secondary_action_text
        }),
        url,
      }
    },
    productSets: {
      $each: {
        max_order_count: {
          required: requiredIf(function(){
            return this.campaign.order_limit_type === 1;
          }),
          minValue: minValue(1)
          // TODO also check max value is less than total prod count
        }
      }
    },
    linkCount: {
      requiredIf: function() {
        if (this.editCampaign) {
          return true
        }
        return this.magicLinksSelected
      },
      minValue: minValue(0),
      maxValue: maxValue(1000)
    },
    validatedEmails: {
      requiredIf: function() {
        if (this.editCampaign) {
          return true
        }
        return this.magicLinksSelected
      },
    },
  },
  mounted: async function(){
    if(this.editCampaign){

      // try to search from admin/campaigns
      const campaign = this.$store.state.admin.campaigns.find(c => c.id === this.$route.params.itemId);
      if(campaign){
        this.campaign = {...campaign};
        this.productIds = [...campaign.products]
        this.productSets = [...campaign.product_sets]
        if (this.productIds.length > 0) {
          this.isLegacyCampaign = true
        }
        if(this.campaign['store_campaign_carrier'][0] !== null && this.campaign['store_campaign_carrier'][0] !== undefined) {
          this.campaign['store_campaign_carrier'] = [...this.campaign['store_campaign_carrier']]
        } else{
          this.campaign['store_campaign_carrier'].push({"carrier":null})
        }
        if(!this.$store.getters.isStaff){
        this.campaign_admin = this.campaign.creator_member_id
        }
      } else {
        // no campaign available, fetch
        this.getCampaign();
      }
    } else {
      let today = new Date();
      today.setDate(today.getDate() + 30);
      const expiration = today.toISOString().split('T')[0];
      this.campaign.expiration_date = expiration;
      this.fillDefaultMessages();
      if(!this.$store.getters.isStaff) {
      this.campaign_admin = this.campCreator
      }
    }
    if(this.$store.state.admin.campTemplatesFetched){
      // templates were fetched, check that campaign has one selected
      if(!this.campaign.template){
        // no template selected, select default
        this.campaign.template = this.defaultTemplate.id;
      }
    }
    if (!this.$store.getters.storeCarriersFetched) {
      try {
        await this.$store.dispatch('fetchStoreCarriers');
      } catch (e) {
        (e)
      }
      const storeCarriers = this.$store.getters.storeCarriers
      for (let carrier in storeCarriers) {
        if (carrier.status === 2) {
          this.activeCarriers = true;
          break;
        }
      }
    }
    if(!this.$store.getters.groupsFetched){
      await this.$store.dispatch('fetchGroups');
    }
    if(!this.$store.getters["admin/membersFetched"]){
      await  this.$store.dispatch('admin/fetchMembers')
    }
    if(!this.$store.getters['admin/profilesFetched']){
      await this.$store.dispatch('admin/fetchProfiles')
    }
    if(!this.$store.getters['admin/productOptsFetched']){
      await this.$store.dispatch('admin/fetchProductOptions');
      return false;
    }
  },
  computed: {
    campCreator() {
      if(!this.$store.getters.membershipFetched){
        return null
      }
      return this.$store.getters.membership.id
    },
    options() {
      return this.$store.getters['admin/productOptions'];
    },
    carrierShippingOptions(){
      if(!this.options.campaign_carrier_shipping_options){
        return [];
      }
      return this.options.campaign_carrier_shipping_options.map(d => ({name: d[1], value: d[0]}))
    },
    fetching(){
      return this.editCampaign ? this.campaign.id ? false : true : false;
    },
    editCampaign(){
      return this.$route.params && this.$route.params.action === "edit";
    },
    activeStore(){
      return this.$store.state.activeStore && this.$store.state.activeStore.id ? true : false;
    },
    storeCurrency() {
      return this.activeStore ? this.$store.state.activeStore.currency_display : ''
    },
    dataFetched(){
      if(!this.$store.state.admin.campaignProductsFetched){
        this.$store.dispatch('admin/fetchProductsForCampaigns');
      }
      return this.$store.state.admin.campaignProductsFetched && this.$store.state.profileFetched;
    },
    productsFetched(){
      if(!this.$store.state.admin.campaignProductsFetched){
        this.$store.dispatch('admin/fetchProductsForCampaigns');
      }
      return this.$store.state.admin.campaignProductsFetched;
    },
    products(){
      return this.$store.state.admin.campaignProducts;
    },
    productObjects(){
      let productIds = this.productSets.map((ps) => {
        return ps.products
      }).flat()
      return this.products.filter(p => productIds.includes(p.id))
    },
    legacyProductObjects() {
      return this.products.filter(p => this.productIds.includes(p.id))
    },
    allProductObjects() {
      return [...this.legacyProductObjects, ...this.productObjects]
    },
    onlyFreeProducts(){
      if(!this.allProductObjects.find(p => p.price != "0.00")){
        return true;
      }
      return false;
    },
    quantityArray(){
      let options = [];
      for (let i = 0; i < this.legacyProductObjects.length; i++) {
        const nbr = i+1;
        const name = nbr + " out of " + this.legacyProductObjects.length + " products.";
        options.push({name: name, value: nbr});
      }
      return options;
    },
    getAllLinks(){
      let links = ""
      if(this.campaign.invitations && this.campaign.invitations.length > 0){
        for (let i = 0; i < this.campaign.invitations.length; i++) {
          let link = this.getLink(this.campaign.invitations[i].token);
          links += link + "\n"
        }
      }
      return links;
    },
    today(){
      const today = new Date().toISOString().split('T')[0];
      return today
    },
    endDateMax(){
      const today = new Date()
      let end_time = today.setDate(today.getDate() + 365);
      let end_date = new Date(end_time)
      return end_date.toISOString().split('T')[0];
    },
    linksUsedCount() {
      if (!this.campaign.invitations) {
        return 0
      }
      return this.campaign.invitations.filter(c => c.used).length
    },
    linksUsedTxt(){
      const percentage = (this.linksUsedCount/this.campaign.invitations.length*100).toFixed(1);
      return this.linksUsedCount + "/" + this.campaign.invitations.length + " used (" + percentage + "%)";
    },
    assignedLinks(){
      if (!this.campaign.invitations) {
        return []
      }
      return this.campaign.invitations.filter(i => i.send_email === true)
    },
    assignedUsed(){
      return this.assignedLinks.filter(l => l.used).length;
    },
    assignedPercentage(){
      if(this.assignedLinks.length){
        return ((this.assignedUsed/this.assignedLinks.length)*100).toFixed(1)
      }
      return 0
    },
    unassignedLinks() {
      if (!this.campaign.invitations) {
        return []
      }
      return this.campaign.invitations.filter(i => i.send_email === false)
    },
    unassignedUsed(){
      return this.unassignedLinks.filter(l => l.used).length;
    },
    unassignedPercentage(){
      if(this.unassignedLinks.length){
        return ((this.unassignedUsed/this.unassignedLinks.length)*100).toFixed(1)
      }
      return 0
    },
    multiInvitationLink() {
      if (this.campaign.multi_invitation !== undefined && this.campaign.multi_invitation !== null) {
        return this.campaign.multi_invitation.link
      }
      return null
    },
    multiInvitationLinkExists() {
      return this.multiInvitationLink !== null
    },
    multiInvitationId() {
      if (this.campaign.multi_invitation !== undefined && this.campaign.multi_invitation !== null) {
        return this.campaign.multi_invitation.id
      }
      return null
    },
    multiInvitationActive() {
      if (this.campaign.multi_invitation !== undefined && this.campaign.multi_invitation !== null) {
        return this.campaign.multi_invitation.active
      }
      return false
    },
    multiInvitationOrders() {
      if (this.campaign.multi_invitation !== undefined && this.campaign.multi_invitation !== null) {
        return this.campaign.multi_invitation.orders.map(obj => ({
          ...obj, isMultiInvitation: true, token: this.campaign.multi_invitation.token
        }))
      }
      return []
    },
    multiInvitationOrdersCount() {
      return this.multiInvitationOrders.length
    },
    apiOrders() {
      return this.campaign.api_orders?.map(apiOrder => ({
        ...apiOrder, isApiOrder: true, token: null
      })) ?? []
    },
    apiOrdersCount() {
      return this.apiOrders.length
    },
    totalOrdersCount() {
      return this.linksUsedCount + this.multiInvitationOrdersCount + this.apiOrdersCount
    },
    allLinks() {
      return [...this.assignedLinks, ...this.unassignedLinks, ...this.multiInvitationOrders, ...this.apiOrders]
    },
    linkTokenOptions() {
      const links = this.allLinks.filter(link => link.token !== undefined && link.token !== null).map(link => link.token)
      return [...new Set(links)]
    },
    linkTypeOptions() {
      return ['API', 'Assigned', 'Unassigned', 'Reusable link']
    },
    linkStatusOptions() {
      return ['Used', 'Not used']
    },
    linkRecipientOptions() {
      const recipients = this.allLinks.map(link => {
        if (link.isMultiInvitation) {
          return link.recipient_name
        } else if (link.send_email && !link.used && link.user_email) {
          return link.user_email
        } else if ((link.send_email || !link.send_email) && link.used && link.usage_time && link.user_name) {
          return link.user_name
        }
      })
      return [...new Set(recipients)]
    },
    filteredLinks() {
      let links = this.allLinks
      if (this.linkFilters.tokenFilter) {
        links = links.filter(l => l.token === this.linkFilters.tokenFilter)
      }
      if (this.linkFilters.typeFilter) {
        switch (this.linkFilters.typeFilter) {
          case 'Assigned':
            links = links.filter(l => l.send_email === true)
            break
          case 'Unassigned':
            links = links.filter(l => l.send_email === false)
            break
          case 'Reusable link':
            links = links.filter(l => l.isMultiInvitation === true)
            break
          case 'API':
            links = links.filter(l => l.isApiOrder === true)
            break
        }
      }
      if (this.linkFilters.statusFilter) {
        switch (this.linkFilters.statusFilter) {
          case 'Used':
            links = links.filter(l => l.used === true || l.isMultiInvitation === true || l.isApiOrder === true)
            break
          case 'Not used':
            links = links.filter(l => l.used === false)
            break
        }
      }
      if (this.linkFilters.recipientFilter) {
        links = links.filter(l => {
          if (l.isMultiInvitation || l.isApiOrder) {
            return l.recipient_name === this.linkFilters.recipientFilter
          } else {
            return l.user_name === this.linkFilters.recipientFilter || l.user_email === this.linkFilters.recipientFilter
          }
        })
      }
      return links
    },
    filteredOrdersCount() {
      return this.filteredLinks.filter(fl => {
        return fl.used === true || fl.isMultiInvitation === true
      }).length
    },
    filteredLinksCount() {
      return this.filteredLinks.length
    },
    ordersUsedPercentage() {
        return this.filteredOrdersCount ? ((this.filteredOrdersCount/this.filteredLinksCount)*100).toFixed(0) : 0
    },
    editEmailsArray(){
      return this.validatedEmails.filter(ve => !this.emailsToBeRemoved.includes(ve))
    },
    allowPayments(){
      return this.$store.getters.storeFeatures.payments_enabled && this.$store.getters.stripeEnabled;
    },
    requirePayment(){
      return this.campaign.require_payment;
    },
    deliveryFee() {
      return this.campaign.delivery_fee
    },
    minChargesValid() {
      if(!this.campaign.require_payment){
        return true;
      }
      if(!this.$store.getters['admin/productOptsFetched']){
        return false;
      }
      else {
        // check all selected products are above the min charge
        const minCharge = this.$store.getters['admin/storeMinCharge'];
        if(!minCharge){ return false; }
        const totalPrice  = this.allProductObjects.reduce((acc, p) => {
          return acc + Number(p.price)
        }, 0);
        // const tooLow = this.productObjects.find(p => Number(p.price) < minCharge);
        if (totalPrice < minCharge) {
          return false;
        }
      }
      return true;
    },
    addresses () {
      if(this.$store.getters.addresses.length < 1 && !this.$store.getters.addressesFetched){
        this.$store.dispatch('fetchAddresses');
      }
      return this.$store.getters.addresses;
    },
    limitAddress() {
      return this.campaign.limit_addresses;
    },
    costcenters () {
      if(this.$store.getters.costcenters.length < 1 && !this.$store.getters.ccsFetched){
        this.$store.dispatch('fetchCostCenters');
        return []
      }
      if(this.$store.getters.membershipFetched){
        if(this.$store.getters.isStaff || this.$store.getters.isAdmin){
          return this.$store.getters.costcenters;
        } else {
          return this.$store.getters.costcenters.filter(cc => this.$store.getters.membership.costcenters.includes(cc.id));
        }
      }
      return []
    },
    userNameList(){
      const groups = this.$store.state.groups.filter(g => g.admin_group === true || g.campaign_create === true)
      let profiles = []
      const groups_members = groups.flatMap(group => group.members);
      if(groups.length > 0 && groups_members){
        const memberIds = groups_members;
        const members = this.$store.getters['admin/storeMembers'].filter(m => memberIds.includes(m.id));
        const userIds = members.map(m => m.user);
        const store_profiles = this.$store.getters['admin/storeProfiles'].filter(p => userIds.includes(p.user.id));
        const profileMap = new Map(store_profiles.map(store_profiles => [store_profiles.user.id, store_profiles]));
        profiles = members.map(member => {
          const profile = profileMap.get(member.user);
          if (profile && profile.user.is_active) {
            return {
              ...profile,
            member_id: member.id
          };
        }
        return null;
        }).filter(member => member !== null);
      }
      let names = profiles.map(p => ({label: p.user.first_name + ' ' + p.user.last_name, value: p.member_id, id: p.user.id }));
      return [...names]
    },
    storeCarriers () {
      if(this.$store.getters.storeCarriers.length < 1 && this.$store.getters.carriers.length < 1){
        this.$store.dispatch('fetchStoreCarriers');
        return []
      }
      const storeCarriers = this.$store.getters.storeCarriers
      const carriers = this.$store.getters.carriers
      const carrierMap = new Map(storeCarriers.map(item => [item.carrier, item]));
      return carriers
        .filter(item => carrierMap.has(item.id))
        .map(item => ({ name: item.name, value: carrierMap.get(item.id).id }));
    },
    carrierSelected() {
      if(this.campaign.store_campaign_carrier[0].carrier === null || this.campaign.store_campaign_carrier[0].carrier === undefined){
        return false
      } else {
        return true
      }
    },
    heroImage(){
      if(!this.showHeroPreview && this.campaign.hero_image){
        if(this.campaign.hero_image.startsWith(process.env.VUE_APP_IMAGE_HOST)){
          return this.campaign.hero_image;
        } else {
          return process.env.VUE_APP_IMAGE_HOST + this.campaign.hero_image;
        }
      } else if(this.showHeroPreview && this.heroPreview){
        return this.heroPreview;
      }
      return null;
    },
    templates() {
      if(!this.$store.state.admin.campTemplatesFetched){
        this.$store.dispatch('admin/fetchCampaignTemplates');
      }
      return this.$store.getters['admin/campaignTemplates']
    },
    defaultTemplate(){
      if(this.templates.length > 0){
        const t = this.templates.find(t => t.public_default == true);
        if(t){
          return t;
        } else {
          // backup
          return this.templates[0];
        }
      }
      return null;
    },
    orderLimitsArray(){
      if(!this.$store.getters['admin/productOptsFetched']){
        return [];
      }
      return this.$store.getters['admin/productOptions'].camp_order_limits.map(d => ({name: d[1], value: d[0]}))
    },
    orderTypesArray(){
      if(!this.$store.getters['admin/productOptsFetched']){
        return [];
      }
      return this.$store.getters['admin/productOptions'].camp_orders_types.map(d => ({name: d[1], value: d[0]}))
    },
    languagesArray(){
      if(!this.$store.getters['admin/productOptsFetched']){
        return [];
      }
      return this.$store.getters['admin/productOptions'].languages.map(d => ({name: d[1], value: d[0]}))
    },
    limitType(){
      return this.campaign.order_limit_type;
    },
    showFirstAddProductSetButton() {
      return this.productIds.length > 0 && this.campaign.order_limit_type === 1 && this.productSets.length === 0
    },
    magicLinksSelected() {
      return this.validatedEmails.length > 0 || this.linkCount > 0 || this.campaign.reusable_link
    },
    showDeliveryFee() {
      return this.campaign.require_payment && (this.limitAddress === false || this.limitAddress === null)
    },
  },
  watch: {
    campaign: function(newVal){
      if (!newVal.email_subject) {
        this.emailDefaults()
      }
    },
    activeStore: function (newVal, oldVal) {
      if(!oldVal && newVal && newVal.id){
        if(this.editCampaign && !this.campaign.id){
          this.getCampaign();
        }
      }
    },
    limitAddress: function(newVal, oldVal) {
      if ((oldVal === true || oldVal === null) && newVal === false) {
        this.campaign.addresses = []
      }
      if ((oldVal === false || oldVal === null) && newVal === true) {
        this.campaign.delivery_fee = 0
      }
    },
    templates: function(newVal, oldVal){
      if(newVal.length > 0 && oldVal.length == 0){
        // templates were fetched, check that campaign has one selected
        if(!this.campaign.template){
          this.campaign.template = this.defaultTemplate.id;
        }
      }
    },
    limitType: function(newVal){
      if(newVal == 3){
        // set max count to null if newVal is Unlimited
        this.campaign.max_order_count = null;
      }
    },
    requirePayment: function(newVal){
      if(!newVal){
        // set allow_invoice to false if require_payment is false
        this.campaign.allow_invoice = false;
        this.campaign.delivery_fee = 0;
        this.campaign.order_method = 1;
        this.campaign.order_approval_admins = [];
      }
    },
    deliveryFee: function(newVal) {
      if (newVal < 0) {
        this.campaign.delivery_fee = 0
      }
    },
    '$store.getters.storeCarriers': {
      handler() {
        if (this.$store.getters.storeCarriers.length !== 0) {
          const storeCarriers = this.$store.getters.storeCarriers
          for (let i = 0; i < storeCarriers.length; i++) {
            if (storeCarriers[i].status === 2) {
              this.activeCarriers = true;
              break;
            }
          }
        }
      },
      immediate: true, // Trigger the watcher immediately when the component is created
    },
  },
  methods: {
    getCampaign(){
      if(!this.activeStore){
        return;
      }
      const vm = this;
      this.updating = true;
      let url = process.env.VUE_APP_API_URL + "/api/campaigns/store/" + this.$store.state.activeStore.id + "/" + this.$route.params.itemId + "/";

      let method = 'GET';
      this.$http({
        method: method,
        url: url,
      })
      .then(function (response) {
        vm.campaign = {...response.data}
        if(!vm.campaign.template && vm.defaultTemplate){
          vm.campaign.template = vm.defaultTemplate.id
        }
        if(vm.campaign['store_campaign_carrier'][0] !== null && vm.campaign['store_campaign_carrier'][0] !== undefined) {
          vm.campaign['store_campaign_carrier'] = [...vm.campaign['store_campaign_carrier']]
        }
        else{
          vm.campaign['store_campaign_carrier'].push({"carrier":null})
        }
        vm.productIds = [...response.data.products]
        vm.productSets = [...response.data.product_sets]
        vm.updating = false;
        if (vm.productIds.length > 0) {
          vm.isLegacyCampaign = true
        }
        if(vm.campaign.creator_member_id && !vm.$store.getters.isStaff){
          vm.campaign_admin = vm.campaign.creator_member_id
        }
      })
      .catch(function (error) {
        if (error.request){
          vm.$toastr.e(
            error.request.responseText
          );
        } else {
          vm.$toastr.e(
            error
          );
        }
        vm.updating = false;
      });
    },
    fillDefaultMessages(){
      if(this.$store.state.profileFetched){
        const userName = this.$store.state.profile.user.first_name + " " + this.$store.state.profile.user.last_name;
        const companyName = this.$store.state.activeStore.company_name;
        this.campaign.intro_header = "Hello there!";
        this.campaign.intro_text = "We at " + companyName + " would like to send you some great items to choose from. We hope you like them!";
        this.campaign.intro_signature =  userName + " from " + companyName;
        this.campaign.thanks_header = "Awesome!";
        this.campaign.thanks_text = "We received your order! Great picks. Our production partners and wizards have just rolled up their sleeves and started fulfiling your order. Shortly, you'll get a tracking code to your email.";
        this.campaign.thanks_signature = userName + " from " + companyName;

        this.emailDefaults();
      }
    },
    emailDefaults(){
      if(this.$store.state.profileFetched){
        const userName = this.$store.state.profile.user.first_name + " " + this.$store.state.profile.user.last_name;
        const companyName = this.$store.state.activeStore.company_name;
        this.campaign.email_subject = "Gifts from the " + companyName;
        this.campaign.email_from_name = userName;
        this.campaign.email_header = "Hello there!";
        this.campaign.email_content = "We at " + companyName + " are excited to work with you. Therefore, we would like to reward you with some gifts 🎁"
        this.campaign.email_signature = userName;
        this.campaign.email_support_text = "If you have questions regarding your order or its delivery please reply to this email. We are happy to help."
      }
    },
    addLegacyProductToCampaign(productId) {
      this.productIds.push(productId);
    },
    addProductToCampaign(productId){
      this.productSets[this.modalProductSetId].products.push(productId);
    },
    removeLegacyProductFromCampaign(productId) {
      if(this.campaign.max_order_count === this.productIds.length){
        // deduct one if max was selected before removing one product
        this.campaign.max_order_count = this.campaign.max_order_count - 1;
      }
      this.productIds = this.productIds.filter(p => p !== productId);
    },
    removeProductFromCampaign(productId){
      if(this.campaign.max_order_count === this.productIds.length){
        // deduct one if max was selected before removing one product
        this.campaign.max_order_count = this.campaign.max_order_count - 1;
      }
      const idx = this.productSets[this.modalProductSetId].products.findIndex(item => item === productId)
      this.productSets[this.modalProductSetId].products.splice(idx, 1)
    },
    saveCampaign(){
      this.updating = true;
      if(this.isLegacyCampaign && this.productIds.length === 0){
        this.$toastr.e(
          "Please select at least one product for this campaign."
        );
        this.updating = false;
        return;
      }
      for (let i = 0; i < this.productSets.length; i++) {
        if(this.productSets[i].products.length === 0){
          this.$toastr.e(
              `Please select at least one product for the product set ${i + 1}.`
          );
          this.updating = false;
          return;
        }
      }
      if(this.allowPayments && this.campaign.require_payment){
        if(!this.minChargesValid){
          const amount = this.$store.getters['admin/storeMinCharge'] + " " + this.$store.getters.currencyStr;
          this.$toastr.e(
            "In order to collect payments, minimum total price needs to be higher than Stripe's minimum charge amount (" + amount + ")"
          );
          this.updating = false;
          return;
        }
        if(this.onlyFreeProducts){
          this.$toastr.e(
            "To be able to collect payments, product prices can not be 0."
          );
          this.updating = false;
          return;
        }
      }
      if(!this.editCampaign){
        if(!this.magicLinksSelected){
          this.$toastr.e(
            "Please add recipient email(s) or a number of links or reusable link to start a campaign."
          )
        }
      }
      this.$v.$touch();
      if(this.$v.$invalid) {
        this.updating = false;
        this.$toastr.e(
          "Please check all fields."
        )
        return;
      }
      const vm = this;
      let data = {...this.campaign};
      data.products = this.productIds;
      data.product_sets = this.productSets

      delete data.hero_image;
      let url = ""
      let method = ""
      if(this.editCampaign){
        url = process.env.VUE_APP_API_URL + "/api/campaigns/store/" + this.$store.getters.activeStore.id + "/" + this.campaign.id + "/";
        method = "PUT";
      } else {
        url = process.env.VUE_APP_API_URL + "/api/campaigns/store/" + this.$store.getters.activeStore.id + "/create_campaign/";
        data['link_count_to_create'] = this.linkCount;
        data['recipient_emails'] = this.validatedEmails;
        method = 'POST';
      }
      this.$http({
        method: method,
        url: url,
        data: data,
      })
      .then(function (response) {
        vm.campaign = {...response.data}
        if(vm.campaign['store_campaign_carrier'] === null || vm.campaign['store_campaign_carrier'].length === 0) {
              vm.campaign['store_campaign_carrier'].push({"carrier":null})
        }
        // update hero image if there is a new one
        if(vm.hero_image && vm.hero_image.name){
          url = process.env.VUE_APP_API_URL + "/api/campaigns/store/" + vm.$store.getters.activeStore.id + "/" + vm.campaign.id + "/";
          vm.uploadHeroImage(url).then(function(result) {
            vm.campaign = {...result.data};
            if(vm.editCampaign){
              vm.$store.dispatch('admin/updateCampaign', result.data);
              vm.productIds = [...result.data.products]
              vm.productSets = [...result.data.product_sets]
              vm.$toastr.s(
                "Your changes have been saved."
              );
            } else {
              vm.$toastr.s(
                "Campaign created successfully."
              );
              vm.$store.dispatch('admin/addCampaign', result.data);
              // redirect to created campaign page
              vm.$router.push({ name: 'campaigns', params: { tab: 'campaign', action: 'edit', itemId: result.data.id }});
            }
            vm.updating = false;
          } );
        }
        else{
          if(vm.editCampaign){
            vm.$store.dispatch('admin/updateCampaign', response.data);
            vm.productIds = [...response.data.products]
            vm.productSets = [...response.data.product_sets]
            if(vm.campaign['store_campaign_carrier'] !== null || vm.campaign['store_campaign_carrier'].length !== 0) {
              vm.campaign['store_campaign_carrier'] = [...vm.campaign['store_campaign_carrier']]
            }
            else{
              vm.campaign['store_campaign_carrier'].push({"carrier":null})
           }
            vm.$toastr.s(
              "Your changes have been saved."
            );
          } else {
            vm.$toastr.s(
              "Campaign created successfully."
            );
            vm.$store.dispatch('admin/addCampaign', response.data);
            // redirect to created campaign page
            vm.$router.push({ name: 'campaigns', params: { tab: 'campaign', action: 'edit', itemId: response.data.id }});
          }
          vm.updating = false;
        }
      })
      .catch(function (error) {
        if (error.request){
          vm.$toastr.e(
            error.request.responseText
          );
        }
        vm.$toastr.e(
          error
        );
        vm.updating = false;
      });
    },
    duplicateCampaign() {
      this.updating = true
      const vm = this

      const method = "POST"
      const url = process.env.VUE_APP_API_URL + "/api/campaigns/store/" + this.$store.getters.activeStore.id + "/" + this.campaign.id + "/duplicate_campaign/"
      this.$http({
        method: method,
        url: url,
      })
      .then(() => {
        if(vm.$store.state.admin.campaignsFetched) {
          vm.$store.dispatch('admin/clearCampaigns')
          vm.$store.dispatch('admin/fetchCampaigns')
          }
        vm.$store.dispatch('fetchOrders')
        vm.$toastr.s("Campaign duplicated successfully.")
        vm.$router.push({ name: 'campaigns'})
      })
      .catch((error) => {
        if (error.request){
          vm.$toastr.e(
              error.request.responseText
          )
        } else {
          vm.$toastr.e(
              error
          )
        }
        vm.updating = false
      })
    },
    deleteCampaign() {
      this.updating = true
      const vm = this

      const method = "PUT"
      const url = process.env.VUE_APP_API_URL + "/api/campaigns/store/" + this.$store.getters.activeStore.id + "/" + this.campaign.id + "/delete_campaign/"

      this.$http({
        method: method,
        url: url,
      })
      .then(() => {
        vm.$store.dispatch('admin/fetchCampaigns')
        vm.$store.dispatch('fetchOrders')
        vm.$toastr.s("Campaign deleted successfully.")
        vm.updating = false
        vm.showDeleteCampaignModal = false
        vm.$router.push({ name: 'campaigns'})
      })
      .catch((error) => {
        if (error.request){
          vm.$toastr.e(
              error.request.responseText
          )
        } else {
          vm.$toastr.e(
              error
          )
        }
        vm.updating = false
        vm.showDeleteCampaignModal = false
      })
    },
    uploadHeroImage(api_url){
      let campaignFormData = new FormData();
      const vm = this;
      const store = this.$store.getters.activeStore;
      campaignFormData.append('hero_image', this.hero_image);
      campaignFormData.append('store', store.id);
      let headers = {};
      headers['Content-Type'] = 'multipart/form-data';
      return this.$http({
        method: 'PATCH',
        url: api_url,
        data: campaignFormData,
        headers: headers
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        if (error.request){
          vm.$toastr.e(error.request.responseText)
        }
        vm.$toastr.e(error);
        document.body.style.cursor='default';
      });
    },
    parseDate(date_str){
      const date = new Date(date_str);
      const month = date.getMonth()+1 < 10 ? '0' + (date.getMonth()+1) : date.getMonth()+1;
      const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      return day + '/' + month + '/' + date.getFullYear();
    },
    getLink(token){
      // framme.store/[store-name]/campaign/[unique identifier]
      let routeData = this.$router.resolve(
      {
        name: 'publicCampaign',
        params: {'token': token}
      });
      const link = window.location.origin + routeData.href;
      return link;
    },
    copyToClip(index){
      var copyText = document.querySelector("#link_"+index);
      copyText.select();
      document.execCommand("copy");
    },
    copyLink(index, invitation_id){
      const vm = this;
      this.updating = true;
      let url = process.env.VUE_APP_API_URL + "/api/campaigns/invitation/" + invitation_id + "/copied/";
      let method = 'GET';
      this.$http({
        method: method,
        url: url,
      })
      .then(function (response) {
        // update local instance
        vm.campaign.invitations = [...vm.campaign.invitations.map(item => item.id !== response.data.id ? item : {...item, ...response.data})]

        const msg = "Magic Link URL has been copied to clipboard.";
        vm.$toastr.s(msg);
        vm.updating = false;
        // copy to clipboard with delay to make sure the element is visible
        setTimeout(function () {
          vm.copyToClip(index);
        }.bind(this), 200);
      })
      .catch(function (error) {
        if (error.request){
          vm.$toastr.e(
            error.request.responseText
          );
        } else {
          vm.$toastr.e(
            error
          );
        }
        vm.updating = false;
      });
    },
    resendLink(index, invitation_id){
      const vm = this;
      this.updating = true;
      let url = process.env.VUE_APP_API_URL + "/api/campaigns/invitation/" + invitation_id + "/resend/";
      let method = 'GET';
      this.$http({
        method: method,
        url: url,
      })
      .then(function (response) {
        // update local instance
        vm.campaign.invitations = [...vm.campaign.invitations.map(item => item.id !== response.data.id ? item : {...item, ...response.data})]

        const msg = "Magic Link URL has been resent.";
        vm.$toastr.s(msg);
        vm.updating = false;
        // copy to clipboard with delay to make sure the element is visible
        setTimeout(function () {
          vm.copyToClip(index);
        }.bind(this), 200);
      })
      .catch(function (error) {
        if (error.request){
          vm.$toastr.e(
            error.request.responseText
          );
        } else {
          vm.$toastr.e(
            error
          );
        }
        vm.updating = false;
      });
    },
    openDeactivateLink(index, id, token){
      this.showDeactivateLinkModal = true
      this.linkIndexToDeactivate = index
      this.linkIdToDeactivate = id
      this.linkTokenToDeactivate = token
    },
    deactivateLink(index, invitation_id){
      const vm = this;
      this.updating = true;
      let url = process.env.VUE_APP_API_URL + "/api/campaigns/invitation/" + invitation_id + "/deactivate/";
      let method = 'GET';
      this.$http({
        method: method,
        url: url,
      })
      .then(function (response) {
        // update local instance
        vm.campaign.invitations = [...vm.campaign.invitations.map(item => item.id !== response.data.id ? item : {...item, ...response.data})]

        const msg = "Magic Link has been deactivated.";
        vm.$toastr.s(msg);
        vm.updating = false;
        vm.showDeactivateLinkModal = false
        setTimeout(function () {
          vm.copyToClip(index);
        }.bind(this), 200);
      })
      .catch(function (error) {
        if (error.request){
          vm.$toastr.e(
            error.request.responseText
          );
        } else {
          vm.$toastr.e(
            error
          );
        }
        vm.updating = false;
      });
    },
    copyAllToClipboard(){
      var copyText = document.querySelector("#all_links");
      copyText.select();
      document.execCommand("copy");
    },
    copyAllLinks(){
      const vm = this;
      this.updating = true;
      let url = process.env.VUE_APP_API_URL + "/api/campaigns/store/" + this.$store.getters.activeStore.id + "/" + this.campaign.id + "/copyall/";
      let method = 'GET';
      this.$http({
        method: method,
        url: url,
      })
      .then(function (response) {
        // update local instance
        if(response.data['store_campaign_carrier'][0] !== null && response.data['store_campaign_carrier'][0] !== undefined) {
          response.data['store_campaign_carrier'] = [...response.data['store_campaign_carrier']]
        } else{
          response.data['store_campaign_carrier'].push({"carrier":null})
        }
        vm.campaign = response.data;

        const msg = "All Magic Link URLs have been copied to clipboard.";
        vm.$toastr.s(msg);
        vm.updating = false;
        // copy to clipboard with delay to make sure the element is visible
        setTimeout(function () {
          vm.copyAllToClipboard();
        }.bind(this), 200);
      })
      .catch(function (error) {
        if (error.request){
          vm.$toastr.e(
            error.request.responseText
          );
        } else {
          vm.$toastr.e(
            error
          );
        }
        vm.updating = false;
      });
    },
    copyReusableLinkToClipboard() {
      if (!this.multiInvitationLink) {
        this.$toastr.e('Reusable Magic Link URL not found.')
        return
      }
      navigator.clipboard.writeText(this.multiInvitationLink)
          .then(() => {this.$toastr.s('Reusable Magic Link URL has been copied to clipboard.')})
          .catch(error => {this.$toastr.e(error)})
    },
    validateAndAddEmails(openModal){
      // split by whitspace and commas
      const emailArray = this.rawRecipientList.trim().split(/[\s,]+/).filter(a => a != "");
      if(emailArray.length > 0 && emailArray[0] != ""){
        let existingEmailSet = new Set(this.validatedEmails);
        if(this.editCampaign){
          // Editing existing campaign --> add already sent emails to validated
          const existingEmails = this.campaign.invitations.filter(i => i.send_email == true).map(i => i.user_email)
          if(existingEmails.length > 0){
            existingEmailSet = new Set([...existingEmailSet, ...existingEmails])
          }
        }
        for(const em of emailArray){
          if(email(em)){
            // valid email, add to validated array if not already there
            if(existingEmailSet.has(em)){
              // already in emails
              this.notValidEmails.push(em);
            } else {
              if(this.emailsToBeAdded.find(email => email == em)){
                // alredy in to-be added array
                this.notValidEmails.push(em);
              } else {
                this.emailsToBeAdded.push(em);
              }
            }
          } else {
            // not valid email
            this.notValidEmails.push(em);
          }
        }
        if(openModal){
          // show modal if requested
          this.showAddEmailsModal = true;
        }
      }
    },
    closeEmailModalAndClear(){
      this.emailsToBeAdded = [];
      this.notValidEmails = [];
      this.rawRecipientList = "";
      this.showAddEmailsModal = false;
    },
    addEmailsToCampaign(){
      // add every email from emailstoadd to validatedEmails
      const emailCount = this.emailsToBeAdded.length;
      this.validatedEmails = [...this.validatedEmails, ...this.emailsToBeAdded]
      this.$toastr.s(
        emailCount + (emailCount == 1 ? " email" : " emails") + " added to campaign successfully."
      );
      this.closeEmailModalAndClear();
    },
    removeEmailFromList(email){
      this.emailsToBeAdded = this.emailsToBeAdded.filter(e => e != email);
    },
    emailToBeRemoved(email){
      this.emailsToBeRemoved.push(email);
    },
    removeEmailsFromCampaign(){
      // remove emailsToBeRemoved from the validated email array
      this.validatedEmails = this.validatedEmails.filter(ve => !this.emailsToBeRemoved.includes(ve))
      this.closeEditEmailModalAndClear();
    },
    closeEditEmailModalAndClear(){
      this.emailsToBeRemoved = [];
      this.showEditEmailsModal = false;
    },
    openAddLinksModal(){
      this.showAddMoreEmailsModal = true;
    },
    closeMoreLinksModalAndClear(){
      this.closeEmailModalAndClear();
      this.showAddMoreEmailsModal = false;
    },
    validateEmailsToAdd(rawList){
      this.rawRecipientList = rawList;
      this.validateAndAddEmails(false);
    },
    addMoreLinksToCampaign(linkCount, multiInvitation){
      this.updating = true;
      const vm = this;

      const data = {
        'link_count_to_create': linkCount,
        'recipient_emails': this.emailsToBeAdded,
      }

      if (this.multiInvitationLinkExists && this.multiInvitationActive !== multiInvitation) {
        data.multi_invitation = {
          'id': this.multiInvitationId,
          'active': multiInvitation,
        }
      } else {
        data.multi_invitation = {
          'active': multiInvitation,
        }
      }

      const url = process.env.VUE_APP_API_URL + "/api/campaigns/store/" + this.$store.getters.activeStore.id + "/" + this.campaign.id + "/create_links/";
      const method = 'POST';

      this.$http({
        method: method,
        url: url,
        data: data,
      })
      .then(function (response) {
        vm.campaign.invitations = [...response.data.invitations]
        vm.campaign.multi_invitation = response.data.multi_invitation
        vm.$store.dispatch('admin/updateCampaign', vm.campaign);
        vm.$toastr.s(
          "More links added to this campaign successfully."
        );
        vm.updating = false;
        vm.closeMoreLinksModalAndClear();
      })
      .catch(function (error) {
        if (error.request){
          vm.$toastr.e(
            error.request.responseText
          );
        }
        vm.$toastr.e(
          error
        );
        vm.updating = false;
      });
    },
    handleFileUpload(){
      let file = this.$refs.hero_image.files[0];

      if(!this.acceptedMimeTypes.includes(file.type)){
        this.$refs.hero_image.value = null;
        this.$toastr.e("Please choose gif, jpg, png or webp file.");
        return;
      } else if(file.size > 4000000){
        this.$refs.hero_image.value = null;
        this.$toastr.e("File size must be under 4MB");
        return;
      }

      this.hero_image = this.$refs.hero_image.files[0];
      // Show preview of the image
      let reader  = new FileReader();
      reader.addEventListener("load", function () {
        this.showHeroPreview = true;
        this.heroPreview = reader.result;
      }.bind(this), false);
      if( this.hero_image ){
        if ( /\.(jpe?g|png|gif|webp)$/i.test( this.hero_image.name ) ) {
          reader.readAsDataURL( this.hero_image );
        }
      }
    },
    resetHeroImage(){
      // Always reset local hero
      this.hero_image = null;
      this.$refs.hero_image.value = '';
      this.showHeroPreview = false;
      this.heroPreview = null;

      // Also reset remote, if saved and editing
      if(this.editCampaign && this.campaign.hero_image){
        this.updating = true;
        const vm = this;
        const data = {'hero_image': null}
        const api_url = process.env.VUE_APP_API_URL + "/api/campaigns/store/" + this.$store.getters.activeStore.id + "/" + this.campaign.id + "/";
        let method = 'PATCH';
        this.$http({
          method: method,
          url: api_url,
          data: data,
        })
        .then(function (response) {
          vm.campaign = {...response.data}
          vm.$store.dispatch('admin/updateCampaign', response.data);
          vm.productIds = [...response.data.products]
          vm.$toastr.s("Image reset successfully.");
          vm.updating = false;
        })
        .catch(function (error) {
          if (error.response && error.response.data && error.response.data.message){
            vm.$toastr.e(
              error.response.data.message
            );
          }
          vm.$toastr.e(
            error
          );
          vm.updating = false;
        });
      }

    },
    getProductSetProductObjects(productSet) {
      if (productSet === undefined) return []
      const products = []
      for (const pid of productSet.products) {
        products.push(this.products.find(p => p.id === pid))
      }
      return products.filter(p => p !== undefined)
    },
    getProductSetQuantity(productSet) {
      let options = [];
      const productSetProductsNumber = this.getProductSetProductObjects(productSet).length
      for (let i = 0; i < productSetProductsNumber; i++) {
        const nbr = i+1;
        const name = nbr + " out of " + productSetProductsNumber + " products.";
        options.push({name: name, value: nbr});
      }
      return options;
    },
    getProductSetProductsNumber(productSet) {
      return this.getProductSetProductObjects(productSet).length
    },
    addProductSet() {
      this.productSets.push({
        id: null,
        campaign: null,
        order_limit_type: 1,
        max_order_count: null,
        products: []
      })
    },
    removeProductSet(idx) {
      this.productSets.splice(idx, 1)
    },
    limitProductSets(value) {
      if (value !== 1 && this.campaign.order_limit_type !== 1) {
        this.productSets.splice(1)
      }
    },
    openAddProductsModal(idx) {
      this.modalProductSetId = idx
      this.showAddProductsModal = true
    },
    closeAddProductsModal() {
      this.modalProductSetId = null
      this.showAddProductsModal = false
    },
    getLinkType(invitation) {
      if (invitation.isApiOrder) {
        return 'API'
      } else if (invitation.isMultiInvitation) {
        return 'Reusable link'
      } else if (invitation.send_email) {
        return 'Assigned'
      } else if (!invitation.send_email) {
        return 'Unassigned'
      }
    },
    getLinkStatus(invitation) {
      if (invitation.isApiOrder || invitation.isMultiInvitation || invitation.used) {
        return 'Used'
      } else {
        return 'Not used'
      }
    },
    getLinkRecipient(invitation) {
      if (invitation.isApiOrder || invitation.isMultiInvitation) {
        return invitation.recipient_name
      } else if (invitation.send_email && !invitation.used && invitation.send_email) {
        return invitation.user_email
      } else if ((invitation.send_email || !invitation.send_email) && invitation.used && invitation.user_name) {
        return invitation.user_name
      } else {
        return 'N/A'
      }
    },
    orderMethodChange(newValue) {
      if(newValue === 1) {
        this.campaign.order_approval_admins = []
      } else if (newValue === 2) {
        if(this.campaign_admin && !this.$store.getters.isStaff) {
        this.campaign.order_approval_admins.push(this.campaign_admin)
        }
      }
    },
    orderAdminsChange(newValue) {
      if(this.campaign_admin !== null && !newValue.includes(this.campaign_admin)){
        this.$toastr.e("Campaign creator cannot be removed.");
        this.campaign.order_approval_admins.unshift(this.campaign_admin)
      }
    },
  }
}
</script>

<style scoped>
.badge-product-set {
  /*font-size: 100%;*/
  color: #1A1818;
  background-color: #eaeaea;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  line-height: 12px;
  vertical-align: middle;
  margin-left: 5px;
}
.btn-add-product {
  background-color: #000000;
}
.btn-add-product:hover {
  color: #000000;
  background-color: #e3e3e3;
  border-color: #e3e3e3;
}
.description {
  min-height: 134px;
}
</style>
